.timelineSection {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: space-between;
}

.timelineAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 20px;
}

.timelineBar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 90%;
  height: 10px;
  background: var(--accent2);
}

.timelineBtnDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 20px 0 0;
  width: 100%;
}
.timelineBtn {
  background-color: var(--accent1);
  border: 1px solid var(--accent2);
  border-radius: 10%;
  padding: 10px;
  color: var(--BG);
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  z-index: 10;
}
.progressMark {
  position: relative;
  top: -2px;
  width: 5px;
  height: 15px;
  background: var(--mainText);
}
.timelineContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  top: -20px;
}
.eventAnimationDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
  height: 80%;
}
.eventListInfoAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.eventListInfoAnimation > span {
  margin: 0;
}

.eventAnimation {
  flex-grow: 5;
  max-width: 700px;
  max-height: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.eventImgDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  max-width: 500px;
  max-height: 600px;
  margin: 0;
  padding: 10px;
}
.eventImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%; 
  max-height: 100%; 
  object-fit: contain;
  margin: 0;
  padding: 0;
}
.eventImgCaption {
  margin-top: 2px;
  font-size: 0.8rem;
  width: 80%;
}
.eventListSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-top: auto;
}
.eventList {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
.eventColumn {
  width: 45%;
}
.listItem {
  margin-bottom: 10px;
}

.eventListBtn {
  background-color: var(--mainText);
  border: 1px solid var(--accent2);
  border-radius: 10%;
  padding: 10px;
  color: var(--BG);
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
  align-self: center;
}

@media (max-width: 575px) {
  .timelineBtn, .eventListBtn {
    font-size: 1.5rem;
    padding: 15px;
  }

  .listItem {
    margin-bottom: 15px;
  }
}
