.musicAnimation {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: chocolate;
}

.sheetMusicContainer {
  width: 100%;
  max-height: 30%;
  background-color: white;
  border: 1px solid black;
  display: flex;
  position: relative;
}
.skillCat {
  color: var(--mainText);
}

.trebleClef {
  height: 100%;
}

.sheetMusic {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;
}

.sheetSpace {
  border-bottom: 1px solid black;
  width: 100%;
  background-color: transparent;
}
.sheetSpace.noteLine {
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: right;
}

.furElise {
  width: 80%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  align-items: flex-start;
  position: absolute;
  top: -59px;
}

.note {
  width: 20px;
  height: fit-content;
  background-color: transparent;
  margin: 0;
  padding: 0;
  position: relative;
  top: -7px;
}

#noteTwo, #noteFour, #noteSeven {
  top: 0;
}

#noteSix {
  top: 17px;
}

#noteEight {
  top: 10px;
}
#noteNine {
  top: 28px;
}

.musicNotesContainer {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.eaMusicNote {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: transparent;
  color: yellow;
  top: 0;
}


.eaMusicNote.Sharp {
  width: 20px;
}

.piano {
  width: 100%;
  height: 50%;
  display: flex;
  align-self: flex-end;
  border: 1px solid black;
}
.pianoKey {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  display: flex;
  vertical-align: top;
  background-color: white;
  justify-content: center;
}

.pianoKey.sharp,
.Dsharp {
  background-color: #000000;
  position: relative;
  height: 70%;
  width: 55%;
  color: #ffffff;
  margin: 0 -13px;
}
