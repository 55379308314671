.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.tabBar {
  display: flex;
  background-color: var(--BG);
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.tabItem {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  background: none;
  flex: 1;
  color: var(--mainText);
  text-align: center;
  transition: background-color 0.3s;
}

.tabItem:hover {
  background-color: var(--accent2);
  color: var(--BG);
}

.tabItem.active {
  border: 1px solid var(--accent2);
  border-bottom: 1px solid var(--BG);
  font-weight: bold;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: -1px;
  z-index: 10;
  border-radius: 5px 5px 0 0;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  height: 100%;
}

.content > div {
  height: 100%;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 2px solid var(--accent2);
}

@media (max-width: 575px) {
  .tabItem {
    padding: 12px 24px;
    font-size: 18px;
  }

  .content, .contentWrapper {
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .tabItem.active {
    box-shadow: none;
    bottom: 0;
  }
}
