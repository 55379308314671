.homeDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  justify-self: center;
  align-self: center;
  color: var(--mainText);
  width: 80%;
  overflow: hidden;
  padding: 20px;
  margin: 20px;
}

.homeDiv::before, 
.homeDiv::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 8px;
}

.homeDiv::before,
.homeDiv2::before {
  top: 0;
  left: 0;
}

.homeDiv::after,
.homeDiv2::after {
  bottom: 0;
  right: 0;
}

.homeDiv2::before,
.homeDiv2::after {
  content: "";
  position: absolute;
  height: 50%;
  width: 8px;
}


/* transparent, blue, red, blue, transparent scheme */
.homeDiv::before {
  background: linear-gradient(to left, transparent 1%, var(--mainText) 33%, var(--accent2) 66%, var(--accent2));
}

.homeDiv2::before {
  background: linear-gradient(to top, transparent 1%, var(--mainText) 33%, var(--accent2) 66%, var(--accent2));
}

.homeDiv::after {
  background: linear-gradient(to right, transparent 1%, var(--mainText) 33%, var(--accent2) 66%, var(--accent2));
}

.homeDiv2::after {
  background: linear-gradient(to bottom, transparent 1%, var(--mainText) 33%, var(--accent2) 66%, var(--accent2));
}



.header {
  text-align: center;
}

.homeContent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  bottom: 10px;
}

.homeContentRight h2 {
  margin: 0;
}

.profilePic {
  max-width: 300px;
  display: flex;
}

.profilePicFrame {
  border: 15px double var(--accent1);
  padding: 15px;
  background-color: var(--mainText);
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .homeContent,
  .homeContentRight,
  .homeContentLeft {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .homeDiv,
  .homeDiv2 {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
