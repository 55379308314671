.header {
  display: flex;
  flex-direction: column;
}

.touchSvg {
  width: 50px;
}

.interestsDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}
.interestsDiv > div {
  border: 1px solid var(--accent2);
  height: 310px;
  width: 310px;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.interest {
  height: 310px;
  width: 310px;
  overflow-y: auto;
  display: block;
}

.locations {
  list-style: circle;
}

@media (max-width: 575px) {
  .interestsDiv {
    align-items: center;
    flex-direction: column;
  }

  .interestsDiv > div {
    height: 80vw;
    width: 80vw;
    margin: 20px;
  }

  .interest {
    height: 80vw;
    width: 80vw;
    text-align: center;
  }
  .locations {
    text-align: start;
  }
}
