.navBarContainer {
  margin-bottom: 20px;
  width: 100%;
}

.navBar {
  position: sticky;
  background-color: var(--accent1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
}

.icon-wrapper {
  padding: 10px;
  border-radius: 50%;
  color: var(--accent2);
  position: relative;
}

.icon-wrapper:hover {
  background-color: var(--BG);
  color: var(--mainText);
}

.icon-wrapper.active {
  background-color: var(--BG);
  color: var(--mainText);
}

.menu-toggle {
  display: none;
}

.collapse-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: var(--accent2);

}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--mainText);
  color: var(--BG);
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}

.icon-wrapper:hover .tooltip-text,
.masterLight:hover .tooltip-text {
  visibility: visible;
}

.masterLight:hover .tooltip-text {
  left: -55px;
}

@media (max-width: 575px) {
  .navBar {
    justify-content: space-evenly;
    position: relative;
  }

  .collapse-menu {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: var(--accent1);
  }

  .collapse-menu.show {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid var(--mainText);
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
    color: var(--accent2);
  }
}
