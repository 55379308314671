.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 80%;
  margin-top: 50px;
  padding: 30px;
}

.contactPage::before,
.contactPage::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 8px;
}

.contactPage::before,
.contactContainer::before {
  top: 0;
  right: 0;
}

.contactPage::after,
.contactContainer::after {
  bottom: 0;
  left: 0;
}

.contactContainer::before,
.contactContainer::after {
  content: "";
  position: absolute;
  height: 50%;
  width: 8px;
}

/* transparent, blue, red, blue, transparent scheme */
.contactPage::before {
  background: linear-gradient(
    to right,
    transparent 1%,
    var(--mainText) 33%,
    var(--accent2) 66%,
    var(--accent2)
  );
}

.contactContainer::before {
  background: linear-gradient(
    to top,
    transparent 1%,
    var(--mainText) 33%,
    var(--accent2) 66%,
    var(--accent2)
  );
}

.contactPage::after {
  background: linear-gradient(
    to left,
    transparent 1%,
    var(--mainText) 33%,
    var(--accent2) 66%,
    var(--accent2)
  );
}

.contactContainer::after {
  background: linear-gradient(
    to bottom,
    transparent 1%,
    var(--mainText) 33%,
    var(--accent2) 66%,
    var(--accent2)
  );
}

.contactHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  text-align: center;
}

.contactHeader * {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactInfoDiv {
  width: fit-content;
  margin-bottom: 20px;
}

.info {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  padding: 0;
}

.linkedInIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf1e6;
  margin: 0;
}

.linkedInIcon > a {
  height: 30px;
}

.linkedImage {
  width: 30px;
  height: 30px;
}

.contactFormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.contactFormDiv {
  width: 100%;
  margin-bottom: 20px;
}

.contactForm {
  background-color: var(--accent1);
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  max-width: 400px;
  display: grid;
}

.formGroup {
  margin-bottom: 10px;
}

.formLabel {
  color: var(--BG);
}

.formInput,
.formTextArea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--accent2);
  background-color: var(--BG);
  color: var(--mainText);
}

.formTextArea {
  resize: none;
  min-height: 100px;
}

.formButton {
  background-color: var(--accent2);
  color: var(--BG);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  justify-self: center;
}

@media (max-width: 575px) {
  .contactPage {
    width: 100%;
    margin-top: 50px;
    padding: 30px;
  }

  .contactInfoDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info {
    
  }

  .contactForm {
    max-width: 100%;
  }
}
