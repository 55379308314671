.card {
  width: 300px;
  min-height: 500px;
  border-radius: 10px;
  background-color: var(--accent2);
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.1);
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: top;
  transition: transform 0.3s;
  color: var(--BG);
}



.cardImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
}
.projImg {
  width: 90%;
  height: 90%;
  object-fit: contain;
  object-position: center;
}


.cardTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  height: fit-content;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 50%;
  width: 100%;
}


.cardTitle > * {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 272px;
  padding: 10px;
  top: 0;
  overflow-y: auto;
}
.cardText:hover {
  color: var(--mainText);
  background-color: var(--BG);
  cursor: pointer;
}

.cardText.blog:hover {
  cursor: default;
}

.cardText {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid var(--accent1);
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--BG);
  color: var(--mainText);
}

.learned {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  text-align: start;
}

.cardFoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 10px;
  margin: 0 0 16px 0;
}
h6 {
  margin: 5px 0;
  font-size: 0.9em;
  text-align: center;
}

.cfContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cardLink {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent2);
  background-color: var(--BG);
  width: 100%;
}
.cardLink:hover {
  color: var(--BG);
  background-color: var(--accent2);
  border: 1px solid var(--accent1);
  cursor: pointer;
}
.ucIconDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 575px) {
  .card {
    width: 90%;
    height: auto;
    margin: 10px 0;
  }
  
  .cardContent{
    height: auto;
    padding: 10px;
  }

  .cardTitle, .cardTitle {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .cardBody {
    height: auto;
    margin-bottom: 10px;
  }

  .cardFoot {
    margin-bottom: 10px;
  }

  .cardText {
    font-size: 0.9em;
  }
}
