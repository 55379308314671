.chessboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 100%;
  height: 100%;
  border: 2px solid black;
}

.chessboardSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.chessboardRow:nth-of-type(odd) .chessboardSquare:nth-of-type(odd),
.chessboardRow:nth-of-type(even) .chessboardSquare:nth-of-type(even) {
  background-color: tan;
}

.chessboardRow:nth-of-type(odd) .chessboardSquare:nth-of-type(even),
.chessboardRow:nth-of-type(even) .chessboardSquare:nth-of-type(odd) {
  background-color: white;
}

.chessboardRow {
  display: contents;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.whitePiece {
  color: var(--accent1);
  font-size: 3.5rem;
}

.blackPiece {
  color: var(--accent2);
  font-size: 3.5rem;
}


@media (max-width: 575px) {
  .whitePiece, .blackPiece {
    font-size: 5rem; /* Adjust the font size for smaller screens */
  }
}
@media (max-width: 425px) {
  .whitePiece, .blackPiece {
    font-size: 3.7rem; /* Adjust the font size for smaller screens */
  }
}
@media (max-width: 415px) {
  .whitePiece, .blackPiece {
    font-size: 3.8rem; /* Adjust the font size for smaller screens */
  }
}
@media (max-width: 390px) {
  .whitePiece, .blackPiece {
    font-size: 3.5rem; /* Adjust the font size for smaller screens */
  }
}