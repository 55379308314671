.skillsSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  font-family: "Arial, sans-serif";
  color: var(--BG);
  background-color: var(--accent1);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.skillsListSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
}

.tier {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.skillList {
  padding: 0;
}

.skill {
  list-style-type: none;
  padding-left: 40px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}

.skill:hover {
  color: var(--accent2);
}

.skillIcon,
.myLogo {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
}

.myLogo {
  color: var(--accent2);
  background-color: var(--BG);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: "Courier New", Courier, monospace;
}

.initials {
  display: flex;
  font-size: 10px;
  flex-direction: column;
}

@media (max-width: 575px) {
  .skillsSection {
    padding: 30px;
  }

  h4,
  h5 {
    font-size: 18px;
  }

  .tier {
    flex-direction: column;
    align-items: center;
  }

  .skillList {
    width: 100%;
    margin-bottom: 20px; /* Adding some space between skill lists when stacked vertically */
  }

  li {
    margin-bottom: 8px; /* Increasing the space between list items */
  }
}
