.projectsBlogsPage {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.sectContent {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 575px) {

  .sectContent {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .vipText2 {
    display: block;
    margin: 5px 0;
  }
}
