.codingAnimation {
  width: 100%;
  height: 100%;
  background-color: black;
  font-family: "Courier New", Courier, monospace;
  padding: 20px;
  padding-right: 0;
  border-radius: 5px;
  position: relative;
  color: #0f0;
}

.terminalLineContainer {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0;
}
#firstLine {
  max-width: 270px;
}
#secondLine {
  max-width: 175px;
}
#thirdLine {
  max-width: 119px;
}
.terminalLine {
  height: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  
  position: relative;
}
.terminalText {
  top: 0;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  display: grid;
  width: 265px;
  margin: 1px;
  left: 0;
  padding: 0;
  position: absolute;
}
@keyframes cursorAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.terminalCursor {
  background-color: #0f0;
  width: 10px;
  height: 20px;
  border-radius: 2px;
  /* animation: cursorAnimation 0.5s infinite; */
}
@media (max-width: 575px) {
  .codingAnimation {
    padding: 0;
  }
  .terminalText {
    width: 100%;
  }
}