* {
  box-sizing: border-box;
}
html {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

body {
  background-color: var(--BG);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#root {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--BG);
  color: var(--mainText);
  top: 0;
  width: 100%;
  max-width: 1300px;
  height: 100%;
}
span {
  color: var(--accent2);
}
.vipText {
  color: var(--accent1);
  font-weight: bold;
}
.vipText2 {
  color: var(--accent);
  font-weight: bold;
}

@media (max-width: 768px) {
  #root {
    font-size: 18px;
  }
}
